.hero-img{
    background-image: url('../hero-img.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 800px;
}

/* small screen  */
@media (max-width: 480px) and (min-width: 320px) {
    .hero-img{
        background-image: url('../hero-img.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 1100px;
    }
    .text-xl{
        font-size: 24px;
    }
    .top-80{
        top: 50% !important;
    }
    .top-30{
        top: 50px !important;
    }
    .right-280{
        right: 23px !important; 
    }
    .left-100{
        left: 26px !important;
    }
    .top-101{
        top: 50px !important;
    }

    .left-150{
        left: 30px !important;
    }
    .left-90{
        left: 50px !important;
    }
    .bottom-70{
        bottom: 180px !important;
    }
    .top-300{
        top: 50% !important;
    
    }
    .right-0{
        right: 0 !important;
    }
    .right-148{
        right: 20px !important;
    }
  
   }

@media (min-width: 481px) and (max-width: 768px) {
    .hero-img{
        background-image: url('../hero-img.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 1100px;
    }
    .text-xl{
        font-size: 24px;
    }
    .top-80{
        top: 50% !important;
    }
    .top-30{
        top: 50px !important;
    }
    .right-280{
        right: 23px !important; 
    }
    .left-100{
        left: 26px !important;
    }
    .top-101{
        top: 50px !important;
    }

    .left-150{
        left: 30px !important;
    }
    .left-90{
        left: 50px !important;
    }
    .bottom-70{
        bottom: 180px !important;
    }
    .top-300{
        top: 50% !important;
    
    }
    .right-0{
        right: 0 !important;
    }
    .right-148{
        right: 20px !important;
    }
  
   }

@media (min-width: 769px) and (max-width: 1024px) {
    .hero-img{
        background-image: url('../hero-img.png');
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 1100px;
    }
    .text-xl{
        font-size: 24px;
    }
    .top-80{
        top: 50% !important;
    }
    .top-30{
        top: 50px !important;
    }
    .right-280{
        right: 23px !important; 
    }
    .left-100{
        left: 26px !important;
    }
    .top-101{
        top: 50px !important;
    }

    .left-150{
        left: 30px !important;
    }
    .left-90{
        left: 50px !important;
    }
    .bottom-70{
        bottom: 180px !important;
    }
    .top-300{
        top: 50% !important;
    
    }
    .right-0{
        right: 0 !important;
    }
    .right-148{
        right: 20px !important;
    }
  
   }

.rounded-left{
    border-radius: 10px 0px 0px 10px;
}

.rounded-top{
    border-radius: 10px 10px 0px 0px;
}


.right-0{
    right: 0;
}
.top-80{
    top: 80px;
}
.left-90{
    left: 90px;
}
.bottom-70{
    bottom: 70px;
}
.top-30{
    top: 30px;
}
.right-280{
    right: 280px;
}
.left-100{
    left: 100px;
}
.top-101{
    top: 100px;
}
.left-150{
    left: 150px;
}
.top-300{
    top: 300px;

}
.pt-6{
    padding-top: 6rem !important;
}
.right-148{
    right: 148px;
}
