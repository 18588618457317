body{
  font-family: 'Satoshi', sans-serif;
}

/* ============== common-classes ================== */
.cursor-pointer{
  cursor: pointer;
}
.bg-light-purple{
background: #EEEBFD;
}
.bg-blue{
  background: #5235E8;
}
.bg-dark-blue{
  background: #231A51;
}
.text-blue{
  color: #5235E8;
}
.text-gray{
  color: #707A8D;
}
.text-dark-black{
  color: #0E0637;
}
.text-sm{
  font-size: 10px;
}
.text-xs{
  font-size: 18px;
}
.text-md{
  font-size: 22px;
}
.text-lg{
  font-size: 40px;
}
.text-xl{
  font-size: 60px;
}

.font-bold{
  font-weight: bold;
}
.font-semibold{
  font-weight:500;
}

.sidebar{
  min-height: 100vh;
}
.box-shadow{
  box-shadow: 0px 12px 80px 0px rgba(82, 53, 232, 0.10);
}
.border-radius{
  border-radius: 36px;
  border: 2px solid #EFEDFD;
}
.input-border{
  border-radius: 10px;

background: #FFF;
}
.border-radius-sm{
  border-radius: 14px;
}

.mh-200{
  min-height: 230px;
}

/* =============== background-images =================== */
footer{
  background: url('./components/Footer/Footer.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* Asphalt-bg */
.asphalt-bg{
  background: url('./components/Asphalt/ashphalt.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
/* =============== ranger-styling ====================== */

input[type="range"] {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  width: 100%;
  cursor: pointer;
  outline: none;
  /*  slider progress trick  */
  overflow: hidden;
  border-radius: 16px;
}

/* Track: webkit browsers */
input[type="range"]::-webkit-slider-runnable-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

/* Track: Mozilla Firefox */
input[type="range"]::-moz-range-track {
  height: 15px;
  background: #ccc;
  border-radius: 16px;
}

/* Thumb: webkit */
input[type="range"]::-webkit-slider-thumb {
  /* removing default appearance */
  -webkit-appearance: none;
  appearance: none; 
  /* creating a custom design */
  height: 20px;
  width: 20px;
  position: relative;
  bottom: 2px;
  z-index: 10;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #524c71;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #5235E8;
}


/* Thumb: Firefox */
input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #f50;
  /*  slider progress trick  */
  box-shadow: -407px 0 0 400px #f50;
}

/* media-queries */

/* Small screens, laptops */
@media (min-width: 320px) and (max-width: 480px) {
  .text-lg{
    font-size: 24px !important;
  }
  .text-sm-sm{
    font-size: 12px !important;
  }
}
/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
 
}
/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
 
}
/* Desktops, large screens */
@media (min-width: 1025px) and (max-width: 1200px) {
 
}
